import * as React from "react"
import { Global } from '@emotion/core';


export const globalStyle = props => <Global
  styles={{
    'html': {
      // scrollbarWidth: 'none',
      // position: 'relative',
    },
    'body': {
      // overflowY: 'scroll',
      overflowX: 'hidden',
      fontFamily: `'Open Sans', sans-serif`,
      margin: 0,
    },
    ...props
  }}
/>

export default globalStyle
