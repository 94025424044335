import * as React from 'react';
import { ThemeProvider } from 'emotion-theming';
import theme from 'src/layouts/styles/theme';
import GlobalStyle from 'src/layouts/globalStyle';


export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      {element}
    </>
  </ThemeProvider>
);
