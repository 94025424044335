const isDark = false;

// spacing
const breakpoints = ['40em', '52em', '78em']

// color
const colors = {
  alpisolRed: '#f32617',
  green: '#009847',
  blue: '#0080FF',
  grayDark: '#32373c',
  grayDark_2: '#707377',
  grayLight_2: '#adafb1',
  grayLight: '#f1f1f1',
  blueDark: '#35507e',
  blueLight: '#5b73ba',
  greenDark: '#005E00',
  greenLight: '#059200',
  black: '#000000',
  white: '#ffffff',
  text: isDark ? 'hsla(0, 0%, 0%, 0.1)' : 'hsla(0, 0%, 0%, 0.8)',
};

// font
const fontSizes = [12, 14, 16, 18, 21, 24, 28, 32, 40, 48]
fontSizes.extraSmall = fontSizes[0]
fontSizes.smaller = fontSizes[1]
fontSizes.small = fontSizes[2]
fontSizes.regular = fontSizes[3]
fontSizes.display = fontSizes[4]
fontSizes.big = fontSizes[5]
fontSizes.bigger = fontSizes[6]
fontSizes.extraBig = fontSizes[7]
fontSizes.huge = fontSizes[8]


export const theme = {
  breakpoints,
  space: [0, 2, 4, 8, 12, 18, 24, 36, 48, 72],

  colors: {
    ...colors,
    brand: colors.alpisolRed,
    primary: isDark ? colors.white : colors.grayDark,
    primary_2: isDark ? colors.grayLight_2 : colors.grayDark_2,
    secondary: isDark ? colors.grayDark : colors.white,
    secondary_2: isDark ? colors.grayDark_2 : colors.grayLight_2,
    tertiary: isDark ? colors.blueLight : colors.blueDark,
    tertiary_2: isDark ? colors.blueDark : colors.blueLight,
    outOfFocus: 'hsla(0, 0%, 0%, 0.2)',  // for invisible button in Navigation/Nav
    error: colors.alpisolRed,
    success: colors.green,
  },

  fonts: {
    openSans: 'Open Sans, sans-serif',
    playfairDisplay: 'Playfair Display, Open Sans, serif',
    manrope: 'Manrope, Open Sans, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes,
  fontWeights: {
    light: 300,
    normal: 400,
    semi: 600,
    bold: 700,
    black: 800,
  },
  lineHeights: {
    small: 1.25,
    normal: 1.5,
    big: 1.75,
  },
  radii: {
    default: 20,
    small: 10,
    circle: 99999,
  },
  shadows: {
    card: '0 0 4px rgba(0, 0, 0, .125)',
  },
  // rebass variants
  // visit: https://github.com/rebassjs/rebass/blob/master/packages/preset/src/index.js#L44
  text: {
    body: {
      fontFamily: 'openSans',
      fontSize: ['regular'],
      fontWeight: 'light',
      lineHeight: 'normal',
      textAlign: 'center',
      color: 'text',
      '& > b, strong': {
        fontWeight: 'semi',
      },
    },
    display: {
      variant: 'text.body',
      fontSize: ['display', 'big'],
      lineHeight: 'big',
    },
    heading: {
      fontFamily: 'manrope',
      fontWeight: 'black',
      lineHeight: 'small',
      textAlign: 'center',
    },
    h1: {
      variant: 'text.heading',
      as: 'h1',
      fontSize: ['extraBig', 'huge'],
    },
    h2: {
      variant: 'text.heading',
      as: 'h2',
      // color: 'accent',
      fontSize: ['bigger', 'extraBig'],
    },
    h3: {
      variant: 'text.heading',
      as: 'h3',
      // color: 'accent2',
      fontSize: ['display', 'big'],
    },
    list: {
      // inherit from the ones before
      textAlign: 'left',
    },
    // -- Footer
    footerText: {
      variant: 'text.body',
      color: 'secondary',
      fontSize: ['smaller', 'small'],
      textAlign: ['center', null, 'left'],
    },
    footerDisclaimer: {
      variant: 'text.footerText',
      fontSize: ['extraSmall', 'smaller'],
    },
  },
  variants: {
    // -- Container
    container: {
      // variant: 'text.body',
      // for flex
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    containerUser: {
      variant: 'variants.container',
      userSelect: 'text',
    },
    containerSafe: {
      variant: 'variants.container',
      overflow: 'hidden',
      userDrag: 'none !important',
      userSelect: 'none !important',
    },
    containerFixed: {
      variant: 'variants.container',
      position: 'fixed',
      top: 0,
      left: 0,
    },
    // -- Primitives
    button: {
      variant: 'text.body',
      borderRadius: 'default',
      borderStyle: 'solid',
      borderWidth: 2,
      // display: 'inline-block',
      fontWeight: 'semi',
      px: 4,
      py: 1,
    },
    linkButton: {
      variant: 'variants.button',
      textDecoration: 'none',
    },
    link: {
      color: 'inherit',
      textDecoration: 'none',
      ':hover': {
        borderBottom: '0.06em solid',
        // textDecoration: 'underline',
      },
    },
    list: {
      // variant: 'variants.container',
      listStyleType: 'circle !important',
      listStylePosition: 'outside',
      // p: 0,
    },
    listItem: {
      variant: 'variants.container',
    },
    image: {
      // variant: 'variants.container',
      // userDrag: 'none', // see 'src/layouts/styles/index' -> draggable
      // userSelect: 'none',
      pointerEvents: 'none !important',
    },
    // -- Layout
    layoutWrapper: {
      variant: 'variants.container',
      // flexDirection: 'column',
      mx: 'auto',
      px: [5, 7, 8],
      maxWidth: breakpoints[breakpoints.length - 1],
    },
    // -- Header
    containerHeader: {
      variant: 'variants.container',
      my: [4, 5],
      // width: '100%',
    },
    outerNavigation: {
      variant: 'variants.containerSafe',
      bg: 'primary',
      // positioning
      position: 'sticky',
      top: 0,

      zIndex: 99,
    },
    navigation: {
      variant: 'variants.layoutWrapper',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    navToggler: {
      variant: 'variants.container',
      cursor: 'pointer',
      display: [null, null, 'none'],
      flexDirection: 'column',
    },
    navTogglerSlice: {
      variant: 'variants.container',
      bg: 'secondary',
      borderRadius: 'small',
      width: '100%',
    },
    nav: {
      variant: 'variants.containerFixed',
      bg: ['primary', null, 'transparent'],
      position: ['fixed', null, 'static'],
      // compensate animated props
      transform: [null, null, 'translate(0px) !important'],
      visibility: [null, null, 'visible !important'],
    },
    navList: {
      variant: 'variants.container',
      flexDirection: ['column', null, 'row'],
      justifyContent: ['center', null, 'flex-end'],
      height: '100%',
      width: '100%',
      listStyle: 'none',
      margin: 'auto',
      padding: [5, 7, 0],
    },
    navListItem: {
      variant: 'variants.container',
      height: [30, null, 'auto'],
      width: ['100%', null, 'auto'],
      my: [5, null, 0],
      paddingLeft: [null, null, 3],
      // py: [3, null, 0],
    },
    navListItemLine: {
      variant: 'variants.container',
      bg: 'primary_2',
      display: [null, null, 'none'],
      height: 1,
      width: 'clamp(50px, 20%, 70px)',
    },
    navLink: {
      // do not use variant 'variants.link' because of hover effect
      variant: 'variants.container',
      height: '100%',
      textDecoration: 'none',
      width: '100%',
      // flex
      display: 'flex',
    },
    navButton: {
      variant: 'variants.button',
      // width: ['clamp(100px, 40%, 140px)', null, 'inherit'],
      fontSize: ['display', null, 'regular'],
    },
    // -- Main
    leftRightElement: {
      variant: 'variants.container',
      my: [4, null, 'none'],
      width: ['100%', null, '75%'],
    },
    formValidationError: {
      color: 'error',
      fontWeight: 'normal',
      ml: [0, 4],
    },
    // -- Footer
    containerFooter: {
      variant: 'variants.containerHeader',
      my: '24px !important',
    },
    footerBlock: {
      variant: 'variants.containerUser',
      justifyContent: 'center',
      width: '100%',
      my: 2,
    },
    footerDisclaimer: {
      variant: 'variants.containerSafe',
      justifyContent: 'center',
      width: '100%',
      my: 2,
    },
    // --
  },
  buttons: {
    primary: {
      variant: 'variants.button',
    },

  },
  forms: {
    form: {
      variant: 'text.body',
      borderRadius: 'default',
      px: 5,
      py: 3,
      textAlign: 'left',
    },
    label: {
      variant: 'forms.form',
      display: 'flex',
      flexDirection: ['column', 'row'],
      fontWeight: 'semi',
    },
    input: {
      variant: 'forms.form',
      mb: 3,
      outline: 'none',
      width: ['100%', null, '50%'],
      '& :focus': {
        boxShadow: 'card',
      },
    },
    textarea: {
      variant: 'forms.input',
      borderBottomRightRadius: 0,
      resize: 'vertical',
      minHeight: 100,
      width: '100% !important',
    },
    submitInput: {
      variant: 'variants.linkButton',
      mx: 'auto',
      my: 5,
      width: 'auto',
      ':hover': {
        cursor: 'pointer',
      },
    },
  },
}


export default theme;
